.home__scripts {
  color: white;
}

.home__scripts-header {
  text-align: center;
  font-family: var(--font-family);
  margin-bottom: 2rem;
}

.home__scripts-container {
  display: flex;
  justify-content: center;
  margin: 0;
}

.script-item {
  position: relative;
  background-color: green;
  margin-left: 4rem;
}

.script-image {
  max-width: 20rem;
}

.script-image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: orangered;
}

.script-image-overlay-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: 0.5s ease;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.script-item:hover .script-image-overlay {
  opacity: 1;
}

.script-item:hover .script-image-overlay-text {
  opacity: 1;
}

@media screen and (max-width: 900px) {
  .home__scripts-container {
    flex-direction: column;
    justify-content: center;
  }
}
