@import url('https://fonts.googleapis.com/css2?family=Averia+Libre:wght@300&family=Chewy&family=Fredoka+One&family=Lilita+One&family=Montserrat:wght@300;400;600;700;800;900&family=Nixie+One&family=Rubik+Glitch&family=Rubik+Wet+Paint&family=Suranna&display=swap');

:root {
  /* --font-family: 'Fredoka One', cursive; */

  --color-bg: #000000;
  --color-latest: #ff0055;
  --color-features: #ffffff; 
  --color-scripts: #ffbf00;
  --color-contact:#ffffff; 
  --color-footer: #031B34;
  --color-button-primary: #ff4000;

  /* --color-button-secondary: */

  

/* --font-family: 'Averia Libre', cursive; */
/* --font-family: 'Chewy', cursive; */
/* --font-family: 'Fredoka One', cursive; */
/* --font-family: 'Lilita One', cursive; */
/* --font-family: 'Nixie One', cursive; */
/* --font-family: 'Rubik Glitch', cursive; */
/* --font-family: 'Rubik Wet Paint', cursive; */
/* --font-family: 'Suranna', serif; */
--font-family: 'Montserrat', sans-serif;
--font-weight: 900;




}

