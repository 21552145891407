.helen__header {
  display: flex;
  padding-bottom: 4rem;
}

.helen__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 5rem;
  padding-left: 5rem;
  max-width: 40%;
}

.helen__header-content h1 {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 700;
}

.helen__header-content p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 600;
  margin-top: 1.5rem;
}

.helen__header-content__social {
  color: #fff;
}

.helen__header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
}

.helen__header-content__input button {
  width: 35%;
  min-height: 50px;
  background: black;
  border: none;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  padding: 0.4rem;
  margin-right: 3rem;
}

.helen__header-content__social {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}

.helen__header-content__social {
  width: 100%;
  display: flex;
}

.helen__header-content__social p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  text-align: center;
}

.helen__header-image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 6rem;
}

.helen__header-image img {
  width: 30rem;
  height: 100%;
}

@media screen and (max-width: 1050px) {
  .helen__header {
    flex-direction: column;
  }

  .helen__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .helen__header h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .helen__header p {
    font-size: 16px;
    line-height: 24px;
  }

  .helen__header-content__social {
    flex-direction: column;
  }

  .helen__header-content__social p {
    margin: 0;
  }

  .helen__header-content__input input,
  .helen__header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .helen__header h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .helen__header p {
    font-size: 14px;
    line-height: 24px;
  }

  .helen__header-content__input input,
  .helen__header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}
