.home__latest-container {
  display: flex;
  background-color: orangered;
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.home__latest-header {
  align-items: flex;
}
.home__latest-header h2 {
  color: white;
  text-align: center;
  font-family: var(--font-family);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.home__latest-image img {
  padding-left: 0rem;
  margin-left: 4rem;
  width: 50rem;
  height: 100%;
  align-items: flex-start;
  flex-direction: column;
}

.home__latest-content button {
  background-color: black;
  color: white;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 5px;
  border-style: none;
  margin-top: 1rem;
  font-family: var(--font-family);
}

.home__latest-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 4rem;
  margin-right: 4rem;
  color: white;
  font-family: var(--font-family);
  max-width: 40em;
}

.home__latest-content h3 {
  margin-bottom: 1rem;
}

@media screen and (max-width: 1050px) {
  .home__latest-container {
    flex-direction: column;
  }

  .home__latest-image img {
    max-width: 30rem;
  }
}
