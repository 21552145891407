.gallery-centre-black {
  display: flex;
  justify-content: center;
}

.gallery-centre-black-gallery {
  background-color: black;
  width: 50%;
  padding-bottom: 1rem;
}

@media screen and (max-width: 800px) {
  .gallery-centre-black {
    flex-direction: column;
    align-items: center;
  }

  .gallery-centre-black-gallery {
    width: 100%;
  }
}
