.gallery-right {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  justify-content: flex-start;
}

.gallery-right-content {
  background-color: orangered;
  display: flex;
  width: 50%;
  height: 50%;
  padding-bottom: 3rem;
}

.gallery-right-content p {
  color: white;
  text-align: justify;
  margin: 6rem;
  font-family: var(--font-family);
}

.gallery-right-gallery {
  /* background-color: orangered; */
  width: 50%;
  padding-bottom: 3rem;
}

/* @media screen and (max-width: 800px) {
  .gallery-right {
    flex-direction: column;
    align-items: center;
  } */

@media screen and (max-width: 800px) {
  .gallery-right {
    flex-direction: column;
    align-items: center;
  }

  .gallery-right-content {
    width: 100%;
  }

  .gallery-right-content {
    width: 100%;
    font-size: small;
  }

  .gallery-right-gallery {
    width: 100%;
  }
}
