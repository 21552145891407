.contact-form-container {
  margin: 20px;
  width: 35%;
}

.contact-form-header {
  background-color: orangered;
  padding: 2rem;
  text-align: center;
  color: white;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 30px;
}

.contact-form {
  display: flex;
  background-color: white;
}

.left-of-form {
  width: 30%;
  height: 100%;
}

.right-of-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 70%;
  padding: 2rem;
}

.contact-form-section {
  float: left;
  margin-bottom: 2rem;
}

.contact-form-section label {
  color: orangered;
  font-family: var(--font-family);
}

.contact-form-input {
  border-radius: 5px;
  height: 3rem;
  width: 100%;
  padding: 10px;
  background-color: rgb(247, 247, 247);
  border: none;
}

.contact-form-message-area {
  border-radius: 5px;
  height: 15rem;
  width: 100%;
  padding: 10px;
  background-color: rgb(247, 247, 247);
  border: none;
}

.contact-form-button {
  padding: 0.7rem;
  color: #fff;
  background: var(--color-button-primary);
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
  height: 4rem;
  margin-top: 2rem;
  margin-left: 1rem;
}
