.gallery-left {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
}

.gallery-left-content {
  background-color: orangered;
  display: flex;
  width: 50%;
  padding-bottom: 3rem;
}

.gallery-left-gallery {
  /* background-color: orangered; */
  width: 50%;
  padding-bottom: 3rem;
}

@media screen and (max-width: 800px) {
  .gallery-left {
    flex-direction: column;
    align-items: center;
  }

  .gallery-left-content {
    width: 100%;
  }

  .gallery-left-gallery {
    width: 100%;
  }
}
