.filmProject__header-title {
  align-items: flex;
}

.filmProject__header-title h1 {
  color: white;
  text-align: center;
  font-family: var(--font-family);
  font-size: 3rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.filmProject__header-main {
  display: flex;
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.filmProject__header-content {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  margin-right: 5rem;
  /* background-color: green; */
  width: 70%;
}

.filmProject__header-content h2 {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 700;
}

.filmProject__header-content p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 600;
  margin-top: 1.5rem;
}

.filmProject__header-content-text {
  /* background-color: orangered; */
  /* padding-bottom: 2rem; */
  height: 30%;
  width: 80%;
  margin-bottom: 3rem;
  margin-left: 0rem;
}

.filmProject__header-content-videos {
  /* background-color: black; */
  height: 70%;
  width: 80%;
}

.filmProject__header-image {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  /* background-color: red; */
  margin-left: 10rem;
  width: 30%;
}

.filmProject__header-image h2 {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 700;
  padding-bottom: 2rem;
}

.filmProject__header-image img {
  width: 100%;
  bottom: 0px;
}

@media screen and (max-width: 1050px) {
  .filmProject__header-main {
    flex-direction: column;
  }

  .filmProject__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .filmProject__header-main h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .filmProject__header-main p {
    font-size: 16px;
    line-height: 24px;
  }

  .filmProject__header-content__social {
    flex-direction: column;
  }

  .filmProject__header-content__social p {
    margin: 0;
  }

  .filmProject__header-content__input input,
  .filmProject__header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .filmProject__header-main h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .filmProject__header-main p {
    font-size: 14px;
    line-height: 24px;
  }

  .filmProject__header-content__input input,
  .filmProject__header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}
