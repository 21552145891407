
.top__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}

.top__navbar-links {
    flex: 1;
    display: flex; 
    justify-content: flex-end;
    align-items: center;
}

.top__navbar-links_logo {
    /* height: 5rem; */
    margin-right: 2rem;
}

.top__navbar-links_logo img {
     max-width: 80px;
     max-height: 80px;
}

.top__navbar-links_container {
    display: flex;
    flex-direction: row;
}

/* contact button */
.top__navbar-contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 2rem;
}

/* target p tags inside container */

.top__navbar-links_container p,
.top__navbar-contact p,
.top__navbar-menu_container p {
    color: rgb(255, 255, 255);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.top__navbar-contact button,
.top__navbar-menu_container button {
     padding: 0.5rem 1rem;
     color: #fff;
     background: var(--color-button-primary);
     font-family: var(--font-family);
     line-height: 25px;
     border-radius: 5px;
     border: 0;
     outline: none;
     cursor: pointer;
}

.top__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.top__navbar-menu svg {
    cursor: pointer;
}

.top__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column; 

    text-align: end; 
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px; 
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);   
}

.top__navbar-menu_container p {
    margin: 1rem 0;
}  

.top__navbar-menu_container-links-contact {
    display: none;
}

@media screen and (max-width: 1050px) {
    .top__navbar-links_container {
        display: none;
    }

    .top__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .top__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .top__navbar {
        padding: 2rem;
    }

    .top__navbar-contact {
        display: none;
    }
}

.top__navbar-menu_container {
    top: 20px;
}

.top__navbar-menu_container-links-contact {
    display: block;
}

/* top__navbar-menu
top__navbar-menu-container shadow-drop-2-center
top__navbar-menu_container-links
top__navbar-menu-container-links-contact */